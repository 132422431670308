<template>
    <v-card flat class="cardBorderStyle d-flex align-center justify-center">
        <div id="chart-container"></div>
    </v-card>
</template>

<script>
import * as d3 from 'd3';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
    name: 'todayBarChart',
    props: {
        userSessionList: Array,
    },
    computed: {
        ...mapGetters(['kioskAnalytics', 'todayBarChart', 'kioskAnalyticsDimension', 'windowSize'])
    },
    watch: {
        kioskAnalytics(newVal) {
            this.createBarChart();
        },
        windowSize(newVal) {
            this.createBarChart();
        }
    },
    data() {
        return {
            //
        };
    },
    mounted() {
        //this.createBarChart();
    },
    methods: {
        createBarChart() {
            d3.select('#chart-container').selectAll('*').remove();

            const width = this.kioskAnalyticsDimension.reportBarChartDimension.width;
            const height = this.kioskAnalyticsDimension.reportBarChartDimension.height;
            const margin = { top: 20, right: 20, bottom: 50, left: 55 };


            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const startingMonthLastYear = (currentMonth + 12 - 12) % 12;
            const categories = this.todayBarChart.map(d => d.category);
            //const slicedCategories = categories.slice(startingMonthLastYear + 1).concat(categories.slice(0, currentMonth + 1));

            const svg = d3.select('#chart-container')
                .append('svg')
                .attr('width', width)
                .attr('height', height);

            const xScale = d3.scaleBand()
                .domain(categories)
                .range([margin.left, width - margin.right])
                .padding(0.5);

            const yScale = d3.scaleLinear()
                .domain([0, d3.max(this.todayBarChart, d => d.value)])
                .nice()
                .range([height - margin.bottom, margin.top]);

            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(xScale));

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', height - margin.bottom + 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('Hours');

            svg.append('g')
                .attr('class', 'y-axis')
                .attr('transform', `translate(${margin.left},0)`)
                .call(d3.axisLeft(yScale));

            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('x', -height / 2)
                .attr('y', margin.left - 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('User Sessions');

            if (this.todayBarChart.every(item => item.value === 0)) {
                svg
                    .append("text")
                    .attr("x", width / 2)
                    .attr("y", height / 2)
                    .attr("text-anchor", "middle")
                    .text("No Data Available");
            } else {
                svg.selectAll('rect')
                    .data(this.todayBarChart)
                    .enter()
                    .append('rect')
                    .attr('x', d => xScale(d.category))
                    .attr('y', d => yScale(d.value))
                    .attr('width', xScale.bandwidth())
                    .attr('height', d => height - margin.bottom - yScale(d.value))
                    .attr('fill', 'steelblue');

                svg.selectAll('.bar-label')
                    .data(this.todayBarChart)
                    .enter()
                    .append('text')
                    .text(d => (d.value !== 0 ? d.value : ''))
                    .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
                    .attr('y', d => yScale(d.value) - 5) // Adjust the vertical position as needed
                    .attr('text-anchor', 'middle')
                    .attr('class', 'bar-label');
            }
        },
    },
};
</script>

<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
    height: 350px !important;
}
</style>